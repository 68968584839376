// import React from "react";
// import Nav1 from "../../Components/Quicklink";
// import Navbar from "../../Components/navigation";

// import Footer3 from "../../Components/footer3";
// import { useState } from "react";

// const Support = () => {


//     const { account, SetOpen } = useState(false);

//     const OpenPop = () => {
//         SetOpen(true)
//     }
//     const ClosePop = () => {
//         SetOpen(false)
//     }
//     return (
//         <>
//             <div className="max-w-[1460px] mx-auto  content-center">
//                 <Nav1 />
//                 <Navbar />


//                 <div className="w-auto">
//                     {/* Hero Section */}
//                     <div className="bg-gray-100 py-16">
//                         <div className=" mx-4 md:mx-[7rem] md:mx-[10rem]  my-12 p-6 md:p-8 bg-white shadow-lg">
//                             <div className="flex flex-col lg:flex-row lg:gap-8 gap-4 items-center">
//                                 {/* Text Content */}
//                                 <div className="flex-1 mx-12 text-center lg:text-left">
//                                     <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold text-gray-800 mb-4">
//                                         Support Our School
//                                     </h1>
//                                     <p className="text-base sm:text-lg lg:text-xl text-gray-600 mb-6">
//                                         Together, we can make a difference in our students' education. Join us in contributing to their future.
//                                     </p>
//                                     <button onClick={{ OpenPop }} className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300">
//                                         Learn More
//                                     </button>
//                                 </div>
//                                 {/* Image */}
//                                 <div className="flex-1">
//                                     <img
//                                         src="https://via.placeholder.com/400"
//                                         alt="School Image"
//                                         className="w-full h-auto object-cover rounded-lg"
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {account % (
//                         <div >



//                             <button onClick={ClosePop} className='w-full px-4 py-2 mx-2 my-2 ' >Close</button>
//                         </div>
//                     )}

//                     {/* Donor Table Section */}
//                     <div className="w-[95%] md:w-[80%] mx-auto py-12 px-4 sm:px-6 lg:px-8">
//                         <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-6 text-center">
//                             Mercy @60 Anniversary Cash Donation
//                         </h2>
//                         <div className="overflow-x-auto">
//                             <table className="min-w-full bg-white shadow-md rounded-lg">
//                                 <thead>
//                                     <tr className="bg-gray-200 text-gray-600 uppercase text-xs sm:text-sm lg:text-base leading-normal">
//                                         <th className="py-3 px-4 sm:px-6 text-left">Donor Name</th>
//                                         <th className="py-3 px-4 sm:px-6 text-left">Amount</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody className="text-gray-600 text-xs sm:text-sm lg:text-base font-light">
//                                     {/* Example rows */}
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Hon. Justice Galadima (RTD)</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">500,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Prof Uwazie</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop Valerian Okeke</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Obi of Onitsha</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Roy Akpuoye</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">50,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Prof Chinedum Bebalola</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">20,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Rev. Fr. JC Nwankwo</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">100,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Rev. Fr. Dr. CJ Ehiem</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">40,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop Opoko</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop Onuoha</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Amb. Orjiakor</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Dr Chris Okoye</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">250,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Lady Esther Ibe</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">10,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Prof Chidi Odinkalu</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">150,000 Naira</td>
//                                     </tr>
//                                     <tr className="border-b border-gray-200 hover:bg-gray-100">
//                                         <td className="py-3 px-4 sm:px-6 text-left">Total</td>
//                                         <td className="py-3 px-4 sm:px-6 text-left">2.320M Naira</td>
//                                     </tr>
//                                     {/* Add more rows as needed */}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>

//                 <Footer3 />
//             </div>
//         </>
//     );
// };
// export default Support;



import React, { useState } from "react";
import Nav1 from "../../Components/Quicklink";
import Navbar from "../../Components/navigation";
import Footer3 from "../../Components/footer3";

const Support = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="max-w-[1460px] mx-auto content-center">
                <Nav1 />
                <Navbar />

                <div className="w-auto">
                    {/* Hero Section */}
                    <div className="bg-gray-100 pt-16 md:pt-16 pb-2 md:pb-16">
                        <div className="mx-4 md:mx-[7rem] lg:mx-[10rem] my-4 md:my-12 p-6 md:p-8 bg-white shadow-lg">
                            <div className="flex flex-col lg:flex-row lg:gap-8 gap-4 items-center">
                                {/* Text Content */}
                                <div className="flex-1 mx-2 md:mx-12 text-center lg:text-left">
                                    <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
                                        Support Our School
                                    </h1>
                                    <p className="text-base sm:text-lg lg:text-xl text-gray-600 mb-6">
                                        Together, we can make a difference in our students' education. Join us in contributing to their future.
                                    </p>
                                    <button onClick={openPopup} className="px-6 text-sm md:text-md py-2 bg-mghso-10 text-white  hover:bg-mghso-12 transition duration-300">
                                        Learn More
                                    </button>
                                </div>
                                {/* Image */}
                                <div className="flex-1">
                                    <img
                                        src="Images/stdntonflag.jpg"
                                        alt="School Image"
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Popup */}
                    {isOpen && (
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
                            <div className="bg-white rounded-lg shadow-lg pb-6 max-w-lg mx-4 md:mx-auto text-center">
                                <h2 className="text-2xl bg-mghso-10 text-white py-6 font-bold mb-4">Make a Donation</h2>
                                <p className="text-gray-700 px-6 mb-6">
                                    To make a donation to the school, please use the account details below:
                                </p>
                                <div className="bg-gray-100 p-4 my-6 rounded-md">
                                    <p className="text-lg font-semibold">Account Number: 6220025291</p>
                                    <p className="text-lg font-semibold">Bank Name: Fidelity Bank</p>
                                    <p className="text-lg font-semibold">School Name: Mercy Secondary School Okigwe</p>

                                </div>
                                <div className="px-6">
                                    <p><strong>For More Info:</strong></p>
                                    <p className="text-sm"><strong>Rev Remy Ogu</strong></p>
                                    <p className="text-sm"><i>Principal</i></p>
                                    <p className="text-sm"><strong className="px-2">Call/text:</strong>2348068491189</p>
                                    <p className="text-sm"><strong className="px-2">Email:</strong>info@mghso.com.ng / mercygirlshighschool@gmail.com</p>
                                </div>
                                <button
                                    onClick={closePopup}
                                    className="mt-6 px-6 py-2 bg-mghso-10 text-white  hover:bg-red-700 transition duration-300"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Donor Table Section */}
                    <div className="w-[95%] md:w-[80%] mx-auto py-12 px-4 sm:px-6 lg:px-8">
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-6 text-center">
                            Mercy @60 Anniversary Cash Donation
                        </h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white shadow-md rounded-lg">
                                <thead>
                                    <tr className="bg-gray-200 text-gray-600 uppercase text-xs sm:text-sm lg:text-base leading-normal">
                                        <th classNam e="py-3 px-4 sm:px-6 text-left">Donor Name</th>
                                        <th className="py-3 px-4 sm:px-6 text-left">Amount</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 text-xs sm:text-sm lg:text-base font-light">
                                    {/* Example rows */}
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Florence Agiriga (MA)</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">Building of Lavatory</td>

                                    </tr> <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Uju Agomoh PhD (MA)</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">Building School Website</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Hon. Justice Sulieman Galadima (RTD)</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">500,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Prof E.E Uwazie</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop Valerian Okeke</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Igwe Nnaeméká Achabe (Obi of Onitsha)</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Roy Akpuoye</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">50,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Prof Chinedum Bebalola</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">20,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Rev. Fr. J.C Nwankwo</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">100,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Rev. Fr. Dr. C.J Ehiem</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">40,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop Chibuzor Opoko</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Arch Bishop David Onuoha</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Amb. Humphrey Orjiakor</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Dr Chris Okoye</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">250,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Lady Esther Ibe</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">10,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Prof Chidi Odinkalu</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">150,000 Naira</td>
                                    </tr>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Ozor Declan Nwokoro</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">200,000 Naira</td>
                                    </tr>
                                    {/* <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 sm:px-6 text-left">Total</td>
                                        <td className="py-3 px-4 sm:px-6 text-left">2.320M Naira</td>
                                    </tr> */}
                                    {/* Add more rows as needed */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Footer3 />
            </div>
        </>
    );
};

export default Support;